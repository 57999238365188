

















import { Component, Vue } from "vue-property-decorator";
import RouteNames from "@/router/RouteNames";
import { WorkspaceType } from "@/dto/auth/Workspace";
import { RawLocation } from "vue-router";

@Component
export default class VacationsMain extends Vue {

  mounted(): void {
    this.automaticRedirection();
  }

  updated(): void {
    this.automaticRedirection();
  }

  async automaticRedirection(): Promise<void> {
    if (this.$route.name === RouteNames.PAYROLL_INDIVIDUAL_VACATIONS || this.$route.name === RouteNames.PAYROLL_BUSINESS_VACATIONS) {
      this.$wss.getCurrent.type === WorkspaceType.PERSON ?
        await this.$router.push(this.routeNameForRequests)
        : await this.$router.push(this.routeNameForList);
    }
  }

  get routeNameForList(): RawLocation {
    return this.$wss.getCurrent.type === WorkspaceType.PERSON ?
      {
        name: RouteNames.PAYROLL_INDIVIDUAL_VACATIONS_LIST,
        params: { personId: this.$wss.getCurrent.id.toString() }
      }
      : {
        name: RouteNames.PAYROLL_BUSINESS_VACATIONS_LIST,
        params: { companyId: this.$wss.getCurrent.id.toString() }
      };
  }

  get routeNameForRequests(): RawLocation {
    return this.$wss.getCurrent.type === WorkspaceType.PERSON ?
      {
        name: RouteNames.PAYROLL_INDIVIDUAL_VACATIONS_REQUESTS,
        params: { personId: this.$wss.getCurrent.id.toString() }
      }
      : {
        name: RouteNames.PAYROLL_BUSINESS_VACATIONS_REQUESTS,
        params: { companyId: this.$wss.getCurrent.id.toString() }
      };
  }

  get routeNameForBalanceDetails(): RawLocation {
    return this.$wss.getCurrent.type === WorkspaceType.PERSON ?
      {
        name: RouteNames.PAYROLL_INDIVIDUAL_VACATIONS_BALANCE_DETAILS,
        params: { personId: this.$wss.getCurrent.id.toString() }
      }
      : {
        name: RouteNames.PAYROLL_BUSINESS_VACATIONS_BALANCE_DETAILS,
        params: { companyId: this.$wss.getCurrent.id.toString() }
      };
  }

}
